<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         show-expand
         >
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
                  />
               <v-spacer />
               <v-btn v-if="$hasRole(subject.kind.toLowerCase(), 'E')" x-large icon tile color="accent" class="mx-1" @click="downloadXLS">
                  <v-icon size="44">
                     mdi-database-export
                  </v-icon>
               </v-btn>
               <v-btn v-if="$hasRole(subject.kind.toLowerCase(), 'I')" x-large icon tile color="accent" class="mx-1" :loading="$store.state.process === 'importSubject'" @click="$refs.xlsxImport.click()">
                  <v-icon size="44">
                     mdi-database-import
                  </v-icon>
                  <input ref="xlsxImport" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" alt="xlsxImport" style="display:none;" @change="uploadXLS">
               </v-btn>
               <v-btn v-if="$hasRole(subject.kind.toLowerCase(), 'C')" x-large icon tile color="secondary" class="mx-1" @click="subject.id = 0">
                  <v-icon size="48">
                     mdi-plus-box
                  </v-icon>
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.zone`]="{ item }">
            <div class="text-truncate">
               {{ zoneText(item.zone) }}
            </div>
         </template>
         <template v-slot:[`item.Logo`]="{ item }">
            <v-btn v-if="item.Logo" icon small tile>
               <v-avatar tile width="30" height="22" @click="downloadLogo(item)">
                  <img :src="setUrlFromImage(item.Logo)" alt="Logo" style="object-fit: cover;" @error="() => { item.Logo = '' }">
               </v-avatar>
            </v-btn>
            <v-icon v-else size="30" class="my-n1">
               mdi-image-area
            </v-icon>
         </template>
         <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
            <td v-if="item.Floors.length" class="pl-2 pr-0">
               <v-btn icon class="v-data-table__expand-icon" :class="{'v-data-table__expand-icon--active' : isExpanded}" @click="expand(!isExpanded)">
                  <v-icon>mdi-chevron-down</v-icon>
               </v-btn>
            </td>
         </template>
         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pb-1">
               <v-chip v-for="(tag, idx) in item.Floors" :key="idx" small label outlined class="mr-1 mt-1 px-2">
                  {{ (floors.find(j => j.identity === tag.identity) || { name: tag.identity }).name }}
               </v-chip>
            </td>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.name}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { imageFromUrl } from '@/utils/nirtara' //, pastel
import { organization } from '@/utils/fields.js'
export default {
   name: 'NOrganizationTable',

   props: {
      subject: { type: Object },
      records: { type: Array },
      zones: { type: Array },
      floors: { type: Array },
      imports: { type: Function },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'Code', value: 'identity', width: 115 },
         { text: 'Name', value: 'name' },
         { text: 'Management', value: 'zone' },
         { text: 'Logo', value: 'Logo', width: 30, align: 'center pr-0', sortable: false },
         { text: '', value: 'data-table-expand' }, // , align: ' pl-2 pr-0'
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            return !search || filteredName
         })
      },
      zoneText () {
         return zone => (this.zones.find(j => j.zone === zone) || { name: zone === '*' ? 'All Management' : zone }).name
      },
   },
   created () {
      this.$store.state.user.code !== 'SPA' && this.columns.splice(this.columns.findIndex(j => j.value === 'zone'), 1)
   },
   methods: {
      uploadXLS () {
         const reader = new FileReader()
         reader.onload = async e => {
            const workbook = await new this.$excelJS.Workbook().xlsx.load(reader.result)
            const rows = []
            workbook.eachSheet(worksheet => {
               if (worksheet.name !== 'Organization') return
               worksheet.eachRow((row, idx) => {
                  if (idx === 1) return
                  const object = {
                     [worksheet.name]: [],
                     name: String(row.values[2] || ''),
                  }
                  const array = row.values.slice(3).filter(s => s).map(s => ({ Floor: [], identity: s }))
                  row.values[1] && row.values[2] && rows.push({
                     kind: worksheet.name,
                     identity: String(row.values[1] || ''),
                     array: array,
                     object: object,
                  })
               })
            })
            // console.log(`uploadXLS (${rows.length}):`, JSON.parse(JSON.stringify(rows)))
            this.imports(rows, len => {
               this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${len} organizations imported successfully` }], kind: 'Success' })
            })
         }
         reader.readAsArrayBuffer(this.$refs[event.target.alt].files[0])
      },
      async downloadXLS () {
         const workbook = new this.$excelJS.Workbook()
         workbook.creator = 'NIRTARA Visitor Management'
         const worksheet = workbook.addWorksheet(this.subject.kind)

         const max = Math.max(...this.filteredRecords.map(j => j.Floors.length))
         const floors = Array.from({ length: max }, (_, i) => ({ [`floor${i + 1}`]: 'Floor' }))
         const column = Object.assign({ identity: 'Code', name: 'Name' }, ...floors)
         worksheet.columns = Object.keys(column).map(s => ({ header: column[s], key: s }))
         worksheet.getRow(1).eachCell(cell => { cell.style = { font: { bold: true, size: 12 } } })
         this.filteredRecords.map(j => {
            const floors = j.Floors.map((s, i) => ({ [`floor${i + 1}`]: s.identity }))
            const row = Object.assign({ identity: j.identity, name: j.name }, ...floors)
            worksheet.addRow(row)
         })

         this.$excelJS.autoWidth(worksheet)

         const saveAs = require('file-saver')
         const buffer = await workbook.xlsx.writeBuffer()
         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
         saveAs(blob, `NVM-50-WEB-${this.$moment().format('YYYYMMDD-HHmmss')}.xlsx`)
      },

      downloadLogo (item) {
         const saveAs = require('file-saver')
         saveAs(imageFromUrl(item.Logo), `${item.identity}-${this.$moment().format('YYYYMMDD-HHmmss')}`)
      },
      modifyRecord (item) {
         Object.assign(this.subject, organization, item)
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
// ::v-deep .v-list-item__action:first-child {
//    margin-right: 16px;
// }
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
   -webkit-box-shadow: none;
   box-shadow: none;
}
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
   -webkit-box-shadow: none;
   box-shadow: none;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.filteredRecords,"dense":"","items-per-page":15,"footer-props":{'items-per-page-options': [15, 30, 60]},"hide-default-footer":!_vm.records.length,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mr-3 hidden-sm-and-down",staticStyle:{"max-width":"240px"},attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","hide-details":"","flat":"","single-line":"","clearable":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-spacer'),(_vm.$hasRole(_vm.subject.kind.toLowerCase(), 'E'))?_c('v-btn',{staticClass:"mx-1",attrs:{"x-large":"","icon":"","tile":"","color":"accent"},on:{"click":_vm.downloadXLS}},[_c('v-icon',{attrs:{"size":"44"}},[_vm._v(" mdi-database-export ")])],1):_vm._e(),(_vm.$hasRole(_vm.subject.kind.toLowerCase(), 'I'))?_c('v-btn',{staticClass:"mx-1",attrs:{"x-large":"","icon":"","tile":"","color":"accent","loading":_vm.$store.state.process === 'importSubject'},on:{"click":function($event){return _vm.$refs.xlsxImport.click()}}},[_c('v-icon',{attrs:{"size":"44"}},[_vm._v(" mdi-database-import ")]),_c('input',{ref:"xlsxImport",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","alt":"xlsxImport"},on:{"change":_vm.uploadXLS}})],1):_vm._e(),(_vm.$hasRole(_vm.subject.kind.toLowerCase(), 'C'))?_c('v-btn',{staticClass:"mx-1",attrs:{"x-large":"","icon":"","tile":"","color":"secondary"},on:{"click":function($event){_vm.subject.id = 0}}},[_c('v-icon',{attrs:{"size":"48"}},[_vm._v(" mdi-plus-box ")])],1):_vm._e()],1),_c('v-divider')]},proxy:true},{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.zoneText(item.zone))+" ")])]}},{key:"item.Logo",fn:function(ref){
var item = ref.item;
return [(item.Logo)?_c('v-btn',{attrs:{"icon":"","small":"","tile":""}},[_c('v-avatar',{attrs:{"tile":"","width":"30","height":"22"},on:{"click":function($event){return _vm.downloadLogo(item)}}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.setUrlFromImage(item.Logo),"alt":"Logo"},on:{"error":function () { item.Logo = '' }}})])],1):_c('v-icon',{staticClass:"my-n1",attrs:{"size":"30"}},[_vm._v(" mdi-image-area ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(item.Floors.length)?_c('td',{staticClass:"pl-2 pr-0"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{'v-data-table__expand-icon--active' : isExpanded},attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pb-1",attrs:{"colspan":headers.length}},_vm._l((item.Floors),function(tag,idx){return _c('v-chip',{key:idx,staticClass:"mr-1 mt-1 px-2",attrs:{"small":"","label":"","outlined":""}},[_vm._v(" "+_vm._s((_vm.floors.find(function (j) { return j.identity === tag.identity; }) || { name: tag.identity }).name)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$hasRole(item.kind.toLowerCase(), 'RU'))?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.modifyRecord(item)}}},[_vm._v(" "+_vm._s(_vm.$hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye')+" ")]):_vm._e(),(_vm.$hasRole(item.kind.toLowerCase(), 'D'))?_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.removeRecord(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-5",staticStyle:{"font-size":"1.15rem"}},[_vm._v(" No data available ")])]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"max-width":"330px"},model:{value:(_vm.dialog.del),callback:function ($$v) {_vm.$set(_vm.dialog, "del", $$v)},expression:"dialog.del"}},[_c('v-card',[_c('v-list-item',{staticClass:"grey lighten-2 px-6 py-2",attrs:{"two-line":""}},[_c('v-list-item-content',{attrs:{"dark":""}},[_c('v-list-item-title',[_vm._v(_vm._s(("Delete item " + (_vm.record.name) + "?")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.record.identity))])],1)],1),_c('v-card-actions',{staticClass:"px-6 py-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"color":"grey darken-2","text":""},on:{"click":function($event){_vm.dialog.del = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"px-4",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.deleteRecord}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }